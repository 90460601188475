import React, { useState, useEffect } from 'react'
import './styles.css'

// functions
import { Cooks, Bracks, Dipps, Persons, Tracking_Recept, Tracking_Recept_Brack, weekdays, currentWeek, weekNumbers, day_index } from '../../api/routes'
import { handleLocalStorage } from '../../utils'

// graphics
import DragAndDrop from './components/DragAndDrop'
import CookDialog from '../cooks/CookDialog'
import DippDialog from '../dipps/DippDialog'
import BrackDialog from '../bracks/BrackDialog'
// material-ui
import Button from '@material-ui/core/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect'
import WithUseAuth from '../auth/WithUseAuth'



function PlannerList(props) {
    const { isAdmin } = props.useAuth;
    const [cooks, setCooks] = useState(null)
    const [bracks, setBracks] = useState(null)
    const [dipps, setDipps] = useState(null)
    const [trackingRecept, setTrackingRecept] = useState(null);
    const [trackingReceptBrack, setTrackingReceptBrack] = useState(null);
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState(null)
    const [persons, setPersons] = useState(null)

    const weeks = weekNumbers()

    const handle = {
        session: {
            get: () => {
                return handleLocalStorage.get("session")
            },
            set: (data) => {
                handleLocalStorage.set("session", data)
            }
        },
        state: {
            set: (name, value) => {
                let newState = { ...state }
                newState[name] = value
                setState(newState)
                handle.session.set(newState)
            },
            setEvent: (event) => {
                const { name, value } = event.target
                handle.state.set(name, value)
            }
        },
        weekdays: async (event) => {
            const { name, value } = event.target
            let newValue = value == 'Alla' ? weekdays : [value]
            handle.state.set(name, newValue)
        },
    }
    
    const data = {
        cooks: {
            read: async () => {
                let _items = await Cooks.Read()
                _items.forEach(item => {item["list"] = weekdays.indexOf(item.dag)})
                setCooks(_items)
                console.log(_items)
            },
            update: async (id, data) => {
                let _items = await Cooks.Update(id, data)
                setCooks(_items)
            },
            upsert: async (data) => {
                data.forEach(item => item.dag = weekdays[item.list])
                let items = await Cooks.Upsert(data)
                
                let newCooks = [...cooks]
                items.map(item => {
                    let index = newCooks.findIndex(i => i.id === item.id)
                    item.list = parseInt(item.list)
                    if (index > -1) newCooks[index] = item
                    else newCooks.push(item)
                })

                setCooks(newCooks)
            }
        },
        bracks: {
            read: async () => {
                let _items = await Bracks.Read()
                _items.forEach(item => {item["list"] = weekdays.indexOf(item.dag)})
                setBracks(_items)
                console.log(_items)
            },
            update: async (id, data) => {
                let _items = await Bracks.Update(id, data)
                setBracks(_items)
            },
            upsert: async (data) => {
                data.forEach(item => item.dag = weekdays[item.list])
                let items = await Bracks.Upsert(data)
                
                let newBracks = [...bracks]
                items.map(item => {
                    let index = newBracks.findIndex(i => i.id === item.id)
                    item.list = parseInt(item.list)
                    if (index > -1) newBracks[index] = item
                    else newBracks.push(item)
                })

                setBracks(newBracks)
            }
        },
        dipps: {
            read: async () => {
                let _items = await Dipps.Read()
                _items.forEach(item => {item["list"] = weekdays.indexOf(item.dag)})
                setDipps(_items)
            },
            update: async (id, data) => {
                let _items = await Dipps.Update(id, data)
                setDipps(_items)
            },
            upsert: async (data) => {
                data.forEach(item => item.dag = weekdays[item.list])
                let items = await Dipps.Upsert(data)
                
                try {
                    let newDipps = [...dipps]
                    items.map(item => {
                        let index = newDipps.findIndex(i => i.id === item.id)
                        item.list = parseInt(item.list)
                        if (index > -1) newDipps[index] = item
                        else newDipps.push(item)
                    })

                    setDipps(newDipps)
                } catch(e) {
                    console.log(e)
                    await data.dipps.read()
                }
            }
        },
        trackingRecept: {
            read: async () => {
                const _trs = await Tracking_Recept.ReadParsed()
                setTrackingRecept(_trs);
            }
        },
        trackingReceptBrack: {
            read: async () => {
                const _trs = await Tracking_Recept_Brack.ReadParsed()
                setTrackingReceptBrack(_trs);
            }
        }
    }

    useEffect(async () => {
        let persons = await Persons.Read()
        setPersons(persons)

        await data.trackingRecept.read()
        await data.trackingReceptBrack.read()
        await data.cooks.read()
        await data.dipps.read()
        await data.bracks.read()
        let _session = await handle.session.get()
        
        let defaultState = {
            activeList: "Kokning",
            activeWeek: currentWeek(),
            activeWeekdays: ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'],
            activeColor: "Alla",
            hideNotDone: false,
            activeYear: new Date().getFullYear()
        }

        if (_session) setState({...defaultState, ..._session})
        else setState(defaultState)

        setLoading(false)
    }, [])


    
    const render = {
        Kokning: () => {
            if (loading || !state || !cooks) return <div>Laddar...</div>
            let items = cooks.filter(item => item.week == state.activeWeek && item.year == state.activeYear).sort((a, b) => a.index - b.index)
            let activeTitle = state.activeWeekdays.length > 1 ? '' : state.activeWeekdays[0]

            let _persons = persons.filter(p => p.kokning)

            let child = item => (
                <div key={item.id} className={`Planner__weekday__item Planner__weekday__item--${item.done ? 4 : item.prio}`}>
                    <CookDialog cook={item} getData={data.cooks.read} persons={_persons} />
                </div>
            )

            let children = {}
            items.map(item => children[item.id] = child(item))

            return <DragAndDrop 
                    items={items} 
                    titles={weekdays} 
                    activeTitle={activeTitle} 
                    handleUpdate={data.cooks.upsert} 
                    hideNotDone={state.hideNotDone}
                    children={children} />
        },
        
        Doppning: () => {
            if (loading || !state || !dipps) return <div>Laddar...</div>
            let items = dipps.filter(item => item.week == state.activeWeek && item.year == state.activeYear)
            if (state.activeColor != "Alla") items = items.filter(item => item.color == state.activeColor)
            
            items.sort((a, b) => a.index - b.index)
            let activeTitle = state.activeWeekdays.length > 1 ? '' : state.activeWeekdays[0]

            let _persons = persons.filter(p => p.doppning)

            let child = item => (
                <div key={item.id} className={`Planner__weekday__item Planner__weekday__item--${item.done ? 4 : item.prio}`}>
                    <DippDialog dipp={item} getData={data.dipps.read} getTrackingReceptData={data.trackingRecept.read} persons={_persons} trackingRecept={trackingRecept}  />
                </div>
            )

            let children = {}
            items.map(item => children[item.id] = child(item))

            return <DragAndDrop 
                    items={items} 
                    titles={weekdays} 
                    activeTitle={activeTitle} 
                    handleUpdate={data.dipps.upsert} 
                    hideNotDone={state.hideNotDone}
                    children={children} />
        },
        Bräck: () => {
            if (loading || !state || !bracks) return <div>Laddar...</div>
            let items = bracks.filter(item => item.week == state.activeWeek && item.year == state.activeYear)
            let activeTitle = state.activeWeekdays.length > 1 ? '' : state.activeWeekdays[0]

            let _persons = persons.filter(p => p.kokning)

            let child = item => (
                <div key={item.id} className={`Planner__weekday__item Planner__weekday__item--${item.done ? 4 : item.prio}`}>
                    <BrackDialog brack={item} getData={data.bracks.read} getTrackingReceptData={data.trackingReceptBrack.read} persons={_persons} trackingRecept={trackingReceptBrack} />
                </div>
            )

            let children = {}
            items.map(item => children[item.id] = child(item))

            return <DragAndDrop 
                    items={items} 
                    titles={weekdays} 
                    activeTitle={activeTitle} 
                    handleUpdate={data.bracks.upsert} 
                    hideNotDone={state.hideNotDone}
                    children={children} />
        }
    }
 
    const isDipps = state && state.activeList == "Doppning"

    return (
            <div className="Planner">
            {loading || !state ? <div>Laddar...</div> : <div className="Planner__main">
                <div className="Planner__main__body">
                    <div className="Planner__h1">Veckoplanering</div>
                    <br />
                    
                    <div className="Planner__main__body__header">

                        <div className="spacer-10" />
                        <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-label">Meny</InputLabel>
                            <NativeSelect
                                value={state.activeList}
                                name="activeList"
                                onChange={e => handle.state.setEvent(e)}
                                inputProps={{ name: 'activeList', id: 'uncontrolled-native', }}
                                sx={{width: "110px"}}
                                >{["Kokning", "Doppning", "Bräck"].map(item => <option key={item} value={item}>{item}</option>)}
                            </NativeSelect>
                        </FormControl>

                        {isDipps && <div className="spacer-10" />}
                        {isDipps && <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-label">Filtrera</InputLabel>
                            <NativeSelect
                                value={state.activeColor}
                                name="activeColor"
                                onChange={e => handle.state.setEvent(e)}
                                inputProps={{ name: 'activeColor', id: 'uncontrolled-native', }}
                                >{["Alla", "Vit", "Ljus", "Mörk"].map(item => <option key={item} value={item}>{item}</option>)}
                            </NativeSelect>
                        </FormControl>}

                        <div className="spacer-10" />

                        <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-label">År</InputLabel>
                            <NativeSelect
                                value={state.activeYear}
                                name="activeYear"
                                onChange={e => {
                                    console.log(state)
                                    handle.state.setEvent(e)
                                }}
                                inputProps={{ name: 'activeYear', id: 'uncontrolled-native', }}
                                >{['2022', '2023', '2024', '2025'].map(item => <option key={item} value={item}>{item}</option>)}
                            </NativeSelect>
                        </FormControl>


                        <div className="spacer-10" />
                        <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-label">Vecka</InputLabel>
                            <NativeSelect
                                value={state.activeWeek}
                                name="activeWeek"
                                onChange={e => handle.state.setEvent(e)}
                                inputProps={{ name: 'activeWeek', id: 'uncontrolled-native', }}
                                >{weeks.map(item => <option key={item} value={item}>{item}</option>)}
                            </NativeSelect>
                        </FormControl>

                        <div className="spacer-10" />
                        <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-label">Dag</InputLabel>
                            <NativeSelect
                                value={state.activeWeekdays}
                                name="activeWeekdays"
                                onChange={e => handle.weekdays(e)}
                                inputProps={{ name: 'activeWeekdays', id: 'uncontrolled-native', }}
                                >{['Alla', ...weekdays].map(item => <option key={item} value={item}>{item}</option>)}
                            </NativeSelect>
                        </FormControl>

                        <div className="spacer-10" />
                        <Button 
                        color="secondary" 
                        variant="outlined" 
                        onClick={() => handle.state.set('hideNotDone', !state.hideNotDone) }
                        size="small"
                        >
                            {!state.hideNotDone ? "Dölj " : "Visa "}
                            Färdiga
                         </Button>

                    </div>
                    <div className="Planner__main__body__list">
                        <div className="Planner__week">
                            {render[state.activeList]()}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default WithUseAuth(PlannerList)